<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_resource-types-help-online'
        }"
      >
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{
            displayLabelName("resources", "resource-types", "resource-types")
          }}
        </div>
        <template v-slot:right>
          <router-link
            v-if="can('MANAGE_RESOURCE_TYPES', 'create')"
            :to="{
              name: 'r_add-resource-type',
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('resources', 'resource-types', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="listOfResourceTypes && listOfResourceTypes.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="resourceType in listOfResourceTypes"
        :key="resourceType.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="editResourceType(resourceType)"
          v-if="!editing"
        >
          <span class="label">
            <span class="highlight">{{ resourceType.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-information"></icon>
            </span>
          </span>
        </button>
        <div class="clebex-item-content-wrapper" v-else>
          <div class="checkbox tiny alt block">
            <input
              type="checkbox"
              :id="`resource-type${resourceType.id}`"
              name="user"
              :selected="selectedResourceTypes.includes[resourceType.id]"
              :value="resourceType.id"
              @change="selectResourceType(resourceType)"
              :disabled="!can('MANAGE_RESOURCE_TYPES', 'delete')"
            />
            <label :for="`resource-type${resourceType.id}`">
              <svg-icon icon="checkmark-strait"></svg-icon>
              <span class="highlight">
                {{ resourceType.name }}
              </span>
            </label>
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: editing }"
              @click="setEditing"
            >
              {{ displayLabelName("resources.resource-types.select") }}
            </button>
          </li>
          <li class="action" v-if="selectedResourceTypes.length && editing">
            <button
              class="action-btn"
              :disabled="processing"
              @click="show = true"
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                selectedResourceTypes.length +
                "/" +
                listOfResourceTypes.length +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="
        displayLabelName('resources.resource-types.delete-confirm-action')
      "
      :cancelButtonLabel="
        displayLabelName('resources.resource-types.delete-cancel-action')
      "
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("resources.resource-types.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("resources.resource-types.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";

export default {
  name: "ResourceTypes",
  mixins: [helpOnlineMixin, paginationMixin],
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: "",
      editing: false,
      helpSlug: "resource-types",
      selectedResourceTypes: []
    };
  },
  computed: {
    ...mapState("resource", ["resourceTypes", "selectedResourceType"]),
    listOfResourceTypes() {
      const query = this.searchQuery;
      if (this.resourceTypes && this.resourceTypes.length) {
        if (query && query.length > 1) {
          return this.resourceTypes.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.resourceTypes;
        }
      }
      return null;
    }
  },
  created() {
    this.setPaginationData(null);
    this.getResourceTypes();
  },
  methods: {
    ...mapActions("resource", [
      "getResourceTypes",
      "setSelectedResourceType",
      "deleteResourceType"
    ]),
    ...mapActions("pagination", ["setPaginationData"]),
    editResourceType(resourceType) {
      if (this.can("MANAGE_RESOURCE_TYPES", "update")) {
        setTimeout(() => {
          this.$router.push({
            name: "r_edit-resource-type",
            params: { resource_type_id: resourceType.id }
          });
        }, 0);
      }
    },
    selectResourceType(resourceType) {
      if (this.selectedResourceTypes.includes(resourceType.id)) {
        this.selectedResourceTypes.splice(
          this.selectedResourceTypes.indexOf(resourceType.id),
          1
        );
      } else {
        this.selectedResourceTypes.push(resourceType.id);
      }
    },
    setEditing() {
      this.setSelectedResourceType(null);
      this.editing = !this.editing;
      this.$router.push({ name: "r_resource-types" });
    },
    async startDelete() {
      this.processing = true;
      for (var i = 0; i < this.selectedResourceTypes.length; i++) {
        await this.deleteResourceType(this.selectedResourceTypes[i]);
      }
      this.setPaginationData(null);
      this.processing = false;
      this.setSelectedResourceType(null);
      this.editing = false;
      this.selectedResourceTypes = [];
      this.$nextTick(() => {
        this.$router.push({ name: "r_resource-types" });
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
